<template>
  <!-- Contact Us Start -->
  <section class="contact" id="contact">
    <div class="container">
      <div class="col-lg-12">
        <div class="contact-detail">
          <h3>{{ $t("contact") }}</h3>
          <p>
            <span class="font-weight-bold"
              >IHGP STEUER GmbH Steuerberatungsgesellschaft</span
            >
          </p>
          <p class="mb-2">
            <span>Oranienstr. 155,</span>
          </p>
          <p>
            <span>10969 Berlin</span>
          </p>
        </div>
        <div class="mt-4 contact-link">
          <div class="mb-3">
            <p class="font-weight-bold">{{ $t("support") }}</p>
            <a href="mailto:support@flinktax.de" class="text-blue"
              ><p>support@flinktax.de</p></a
            >
          </div>
          <div>
            <p class="font-weight-bold">{{ $t("everything") }}</p>
            <a href="mailto:support@flinktax.de" class="text-blue"
              ><p>mail@flinktax.de</p></a
            >
          </div>
        </div>
        <!-- <div class="col-lg-5 font-size-12">
          <div class="custom-form mb-5 mb-lg-0">
            <div id="message"></div>
            <form name="contact-form" id="contact-form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Vorname</label>
                    <input id="name" type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="surname">Nachname</label>
                    <input id="surname" type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="email">E-Mail-Adresse *</label>
                    <input id="email" type="email" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="comments">Nachricht schreiben ...</label>
                    <textarea
                      id="comments"
                      rows="4"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <button type="button" class="blue-button font-size-15">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> -->
      </div>
    </div>
    <div
      style="
        overflow: hidden;
        resize: none;
        max-width: 100%;
        width: 100%;
        height: 500px;
        margin-top: 80px;
      "
    >
      <div
        id="google-maps-display"
        style="height: 100%; width: 100%; max-width: 100%"
      >
        <iframe
          style="height: 100%; width: 100%; border: 0"
          frameborder="0"
          src="https://www.google.com/maps/embed/v1/place?q=https://www.google.com/maps/place/Oranienstra%C3%9Fe+155,+10969+Berlin,+Almanya/@52.5029632,13.4101351,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84e2df307f561:0xbfd36331f85947d!8m2!3d52.50296!4d13.41271!16s%2Fg%2F11c2f156l6?entry=ttu&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
        ></iframe>
      </div>
    </div>
  </section>
  <!-- Contact Us End -->
</template>
<script>
import {
  SendIcon,
  MailIcon,
  LinkIcon,
  PhoneCallIcon,
  ClockIcon,
  MapPinIcon,
} from "vue-feather-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faLinkedin,
  faTwitter,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
export default {
  components: {
    SendIcon,
    MailIcon,
    LinkIcon,
    PhoneCallIcon,
    ClockIcon,
    MapPinIcon,
    FontAwesomeIcon,
  },
  data() {
    return {
      faLinkedin,
      faFacebookF,
      faTwitter,
    };
  },
};
</script>
<style lang="scss">
.contact {
  padding: 160px 0 0 0;

  .contact-detail {
    h3 {
      text-transform: uppercase;
    }
  }

  .contact-icon {
    svg {
      height: 20px;
      color: #000;
    }
  }

  .contact-link {
    a {
      p {
        text-decoration: underline;
      }
    }
  }
  #google-maps-display img.text-marker {
    max-width: none !important;
    background: none !important;
  }
  img {
    max-width: none;
  }
}
</style>