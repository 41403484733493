var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"contact",attrs:{"id":"contact"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"contact-detail"},[_c('h3',[_vm._v(_vm._s(_vm.$t("contact")))]),_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"mt-4 contact-link"},[_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("support")))]),_vm._m(3)]),_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("everything")))]),_vm._m(4)])])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("IHGP STEUER GmbH Steuerberatungsgesellschaft")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-2"},[_c('span',[_vm._v("Oranienstr. 155,")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("10969 Berlin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-blue",attrs:{"href":"mailto:support@flinktax.de"}},[_c('p',[_vm._v("support@flinktax.de")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-blue",attrs:{"href":"mailto:support@flinktax.de"}},[_c('p',[_vm._v("mail@flinktax.de")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"hidden","resize":"none","max-width":"100%","width":"100%","height":"500px","margin-top":"80px"}},[_c('div',{staticStyle:{"height":"100%","width":"100%","max-width":"100%"},attrs:{"id":"google-maps-display"}},[_c('iframe',{staticStyle:{"height":"100%","width":"100%","border":"0"},attrs:{"frameborder":"0","src":"https://www.google.com/maps/embed/v1/place?q=https://www.google.com/maps/place/Oranienstra%C3%9Fe+155,+10969+Berlin,+Almanya/@52.5029632,13.4101351,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84e2df307f561:0xbfd36331f85947d!8m2!3d52.50296!4d13.41271!16s%2Fg%2F11c2f156l6?entry=ttu&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"}})])])
}]

export { render, staticRenderFns }