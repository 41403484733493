<template>
  <div>
    <Navbar />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Contact from "../components/contact/index.vue";
import Navbar from "../components/shared/Navbar.vue";
import Footer from "../components/shared/Footer.vue";

export default {
  components: { Navbar, Contact, Footer },
  created() {
    const lang = this.$i18n.locale === "tr" ? "" : this.$i18n.locale;
    alert(this.$i18n.locale)
    location.href = `https://flinktax.de/${lang}/kontakt`;
  },
};
</script>

<style></style>
